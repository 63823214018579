import clsx from 'clsx';
import React, { ChangeEvent, DragEventHandler, ReactNode, useState } from 'react';

import styles from './FileDragAndDrop.module.scss';

type Props = {
  inputId: string;
  handleFiles: (fileList: FileList) => Promise<void>;
  onChange?: (e: React.ChangeEvent<HTMLInputElement>) => void;
  onUploadProgress?: (progress?: number) => void;
  name?: string;
  icon: ReactNode;
  acceptedExtensions?: string;
  containerClassName?: string;
  error?: boolean;
  dragAreaChildren?: ReactNode;
  multiple?: boolean;
};

export const FileDragAndDrop = React.forwardRef<HTMLInputElement, Props>((props, ref) => {
  const {
    inputId,
    handleFiles,
    icon,
    containerClassName,
    acceptedExtensions,
    error,
    name,
    dragAreaChildren,
    onUploadProgress,
    multiple = false,
  } = props;
  const [dragActive, setDragActive] = useState(false);

  const isFileDragged = (e: React.DragEvent<HTMLElement>) => {
    return !Array.from(e.dataTransfer.items).some((x) => x.kind !== 'file');
  };

  const handleDrag = (e: React.DragEvent<HTMLFormElement> | React.DragEvent<HTMLDivElement>) => {
    e.preventDefault();
    e.stopPropagation();

    if (!isFileDragged(e)) return;

    if (e.type === 'dragenter' || e.type === 'dragover') {
      setDragActive(true);
    } else if (e.type === 'dragleave') {
      setDragActive(false);
    }
  };

  const handleUpload = async (files: FileList) => {
    if (onUploadProgress) {
      const reader = new FileReader();
      reader.onloadstart = () => onUploadProgress(0);
      reader.onprogress = (e) => {
        onUploadProgress((e.loaded / e.total) * 100);
      };
      reader.onloadend = () => onUploadProgress(undefined);
      reader.readAsDataURL(files[0]);
    }

    await handleFiles(files);
  };

  const handleDrop: DragEventHandler<HTMLDivElement> = async (e) => {
    e.preventDefault();
    if (!isFileDragged(e)) return;

    await handleUpload(e.dataTransfer.files);
    setDragActive(false);
  };

  const handleChange = async (e: ChangeEvent<HTMLInputElement>) => {
    props.onChange?.(e);
    if (!e.currentTarget.files) return;

    await handleUpload(e.currentTarget.files);
  };

  return (
    <div
      data-testid={'drag-and-drop'}
      onDragOver={handleDrag}
      className={clsx(styles.dragAndDropForm__container, containerClassName)}
    >
      <input
        className={styles.dragAndDropForm__input}
        type="file"
        id={inputId}
        data-testid={'drag-and-drop-input'}
        accept={acceptedExtensions}
        onChange={handleChange}
        ref={ref}
        name={name}
        hidden={true}
        multiple={multiple}
      />
      <div
        className={clsx(styles.dragAndDropForm__label, {
          [styles.dragAndDropForm__label_dragActive]: dragActive,
          [styles.dragAndDropForm__label_error]: error,
        })}
      >
        <div className={styles.dragAndDropForm__dropArea}>
          <div>{icon}</div>
          <span className={styles.dragAndDropForm__monoText}>Drag & drop</span>
          <span>
            or&nbsp;
            <label htmlFor={inputId} className={styles.dragAndDropForm__link}>
              choose a file
            </label>
            &nbsp;to&nbsp;upload
          </span>
          {dragAreaChildren}
        </div>
      </div>
      {dragActive && (
        <div
          data-testid={'drag-and-drop-area'}
          className={styles.dragAndDropForm__stub}
          onDragLeave={handleDrag}
          onDragOver={handleDrag}
          onDrop={handleDrop}
        />
      )}
    </div>
  );
});
