import { FieldValues, FormState } from 'react-hook-form';
import { FieldErrors } from 'react-hook-form/dist/types/errors';
import { MsgUnexpectedError } from './useApplicationForm';

export const getIsFormErrored = <T extends FieldValues>(formState: FormState<T>): boolean =>
  Object.keys(formState.errors).length > 0;

export const getFormError = <T extends FieldValues>(errors: FieldErrors<T>): string =>
  Object.values(errors)
    .map((error) => error?.message ?? MsgUnexpectedError)
    .join('; ');
