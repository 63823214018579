import { Popover } from '@mui/material';
import { ColumnDef } from '@tanstack/react-table';
import React, { useMemo, useState } from 'react';

import { getFirmwareGenerationInfo, UploadFirmwareFileDto } from 'api/firmware-api';
import { AppHint } from 'components/AppHint/AppHint';
import { StagePicker } from '../../../../StagePicker/StagePicker';
import { ReleaseDateTimePicker } from '../../../ReleaseDateTimePicker';
import { FirmwareFileActions } from '../../useUploadFirmwareFilesForm/useUploadFirmwareFilesForm';
import ChevronIcon from '/src/assets/icons/chevron.svg';
import CrossIcon from '/src/assets/icons/close-cross.svg';

import styles from './useUploadFirmwareFilesColumns.module.scss';

export type UploadFirmwareFilesColumnsAttributes = {
  stages: string[];
  actions: FirmwareFileActions;
};

export const useUploadFirmwareFilesColumns: (
  props: UploadFirmwareFilesColumnsAttributes,
) => ColumnDef<UploadFirmwareFileDto>[] = ({
  stages,
  actions: { removeFile, setFileSelectedStages, setFileReleaseDate },
}) =>
  useMemo(
    () => [
      {
        id: 'fileName',
        accessorKey: 'fileName',
        enableSorting: false,
        cell: ({ row }) => (
          <AppHint
            openDelay={60}
            component={<div className={styles.fileName}>{row.original.file?.name}</div>}
            children={row.original.file?.name}
            className={styles.fileNameContainer}
          />
        ),
        header: 'File name',
        size: 300,
        meta: {
          minSize: 150,
        },
      },
      {
        id: 'generation',
        accessorKey: 'generation',
        enableSorting: true,
        cell: ({ row }) => (
          <div className={styles.generation}>{getFirmwareGenerationInfo(row.original.firmwareGeneration).name}</div>
        ),
        header: 'Generation',
        size: 120,
        meta: {
          minSize: 100,
        },
      },
      {
        id: 'version',
        accessorKey: 'version',
        enableSorting: false,
        cell: ({ row }) => <div className={styles.version}>{row.original.version}</div>,
        header: 'FW version',
        size: 120,
        meta: {
          minSize: 100,
        },
      },
      {
        id: 'stages',
        accessorKey: 'stages',
        enableSorting: false,
        cell: ({ row }) => {
          const [anchorEl, setAnchorEl] = useState<HTMLDivElement | null>(null);
          const fileStages = row.original.stages;

          return (
            <div data-testid={`table__row-${row.index}__stages`} className={styles.stages}>
              {fileStages.length !== 0 ? fileStages.join(', ') : <span className={styles.stagesNone}>none</span>}
              <div
                data-testid={`table__row-${row.index}__stages__chevron`}
                onClick={(e) => (!anchorEl ? setAnchorEl(e.currentTarget) : setAnchorEl(null))}
              >
                <ChevronIcon className={styles.stagesChevron} />
              </div>
              <Popover
                open={!!anchorEl}
                anchorEl={anchorEl}
                anchorOrigin={{ horizontal: 'left', vertical: 'bottom' }}
                onClose={() => setAnchorEl(null)}
                slotProps={{
                  paper: {
                    sx: {
                      padding: '6px 4px',
                    },
                  },
                }}
              >
                <StagePicker
                  stages={stages.map((x) => ({
                    name: x,
                    isSelected: row.original.stages.includes(x),
                    isLocked: false,
                  }))}
                  onChange={(stages) => setFileSelectedStages(row.index, stages)}
                />
              </Popover>
            </div>
          );
        },
        header: 'Stages',
        size: 210,
        meta: {
          minSize: 100,
        },
      },
      {
        id: 'releaseDate',
        accessorKey: 'releaseDate',
        enableSorting: false,
        cell: ({ row }) => (
          <div data-testid={`table__row-${row.index}__release-date`}>
            <ReleaseDateTimePicker
              size={'short'}
              value={row.original.releaseDate}
              onChange={(date) => setFileReleaseDate(row.index, date)}
            />
          </div>
        ),
        header: 'Release date',
        size: 210,
        meta: {
          minSize: 160,
        },
      },
      {
        id: 'remove',
        accessorKey: 'remove',
        enableSorting: false,
        cell: ({ row }) => (
          <div className={styles.remove}>
            <CrossIcon
              data-testid={`table__row-${row.index}__remove-button`}
              className={styles.removeIcon}
              onClick={() => removeFile(row.index)}
            />
          </div>
        ),
        header: () => null,
        size: 24,
        meta: {
          minSize: 24,
          bodyCellProps: {
            className: styles.removeCell,
          },
          headCellProps: {
            className: styles.removeCell,
          },
        },
      },
    ],
    [JSON.stringify(stages)],
  );
