import clsx from 'clsx';
import React from 'react';

import { Loading } from '../Loading/Loading';

import styles from './AppForm.module.scss';

export type AppFormProps = React.HTMLAttributes<HTMLFormElement> & {
  title?: string;
  actions?: React.ReactNode[];
  helperText?: React.ReactNode;
  error?: boolean;
  isLoading?: boolean;
  uploadProgress?: number;
  preserveInfoPanelSpace?: boolean;
  classNames?: {
    content?: string;
    infoPanel?: string;
  };
};

export const AppForm: React.FC<AppFormProps> = (props) => {
  const {
    className,
    children,
    title,
    isLoading,
    actions,
    error,
    helperText,
    preserveInfoPanelSpace = true,
    uploadProgress,
    classNames,
    ...rest
  } = props;

  return (
    <form className={clsx(className, styles.form)} {...rest}>
      {title && <div className={styles.title}>{title}</div>}
      <div className={clsx(styles.contentPanel, classNames?.content)}>{children}</div>
      {(helperText || preserveInfoPanelSpace) && (
        <div className={styles.infoPanel}>
          <div data-testid={error ? 'form__error' : 'form__helper-text'} className={clsx(styles.infoPanelContent, classNames?.infoPanel)} aria-invalid={error}>
            {helperText}
          </div>
        </div>
      )}
      {actions && <div className={clsx(styles.actionsPanel)}>{actions}</div>}
      <Loading progress={uploadProgress && Math.round(uploadProgress)} loading={!!isLoading} />
    </form>
  );
};
