import { ColumnDef, createColumnHelper } from '@tanstack/react-table';
import React, { useMemo } from 'react';

import { FirmwareFileDto, getFirmwareGenerationInfo } from 'api/firmware-api';
import { AppHint } from 'components/AppHint/AppHint';
import { SortableHeader, SortingControl } from 'components/SortableHeader/SortableHeader';
import { formatAsDate } from 'helpers/dateUtils';
import { FirmwareFileMenu } from './FirmwareFileMenu/FirmwareFileMenu';

import styles from './FirmwarePage.module.scss';

export type FirmwarePageColumnsAttributes = {
  uploadedAtSortingControl: SortingControl;
  versionSortingControl: SortingControl;
};

const columnHelper = createColumnHelper<FirmwareFileDto>();

export const useFirmwarePageColumns: (props: FirmwarePageColumnsAttributes) => ColumnDef<FirmwareFileDto>[] = ({
  uploadedAtSortingControl,
  versionSortingControl,
}) =>
  useMemo(
    () => [
      {
        id: 'generation',
        accessorKey: 'generation',
        enableSorting: true,
        cell: ({ row }) => (
          <div className={styles.generation}>{getFirmwareGenerationInfo(row.original.firmwareGeneration).name}</div>
        ),
        header: 'GENERATION',
        size: 120,
        meta: {
          minSize: 120,
        },
      },
      {
        id: 'version',
        accessorKey: 'version',
        enableSorting: false,
        cell: ({ row }) => <div className={styles.version}>{row.original.version}</div>,
        header: () => 'FW VERSION',
        size: 110,
        meta: {
          minSize: 110,
        },
      },
      {
        id: 'stages',
        accessorKey: 'stages',
        enableSorting: false,
        cell: ({ row }) => {
          const stages = row.original.stages;
          return (
            <div className={styles.stages} data-none={stages.length === 0}>
              {stages.length > 0 ? stages.join(', ') : 'None'}
            </div>
          );
        },
        header: 'STAGES',
        size: 170,
        meta: {
          minSize: 100,
        },
      },
      {
        id: 'releaseDate',
        accessorKey: 'releaseDate',
        enableSorting: false,
        cell: ({ row }) => <div className={styles.releaseDate}>{formatAsDate(row.original.releaseDate)}</div>,
        header: 'RELEASE DATE',
        size: 130,
        meta: {
          minSize: 130,
        },
      },
      {
        id: 'uploadedAt',
        accessorKey: 'uploadedAt',
        enableSorting: false,
        cell: ({ row }) => <div className={styles.uploadedAt}>{formatAsDate(row.original.uploadedAt)}</div>,
        header: () => <SortableHeader sortingControl={uploadedAtSortingControl} content={'UPLOADED AT'} />,
        size: 130,
        meta: {
          minSize: 130,
        },
      },
      {
        id: 'uploadedBy',
        accessorKey: 'uploadedBy',
        enableSorting: false,
        cell: ({ row }) => (
          <AppHint
            openDelay={60}
            component={<span className={styles.uploadedBy}>{row.original.uploadedBy}</span>}
            children={row.original.uploadedBy}
          />
        ),
        header: 'UPLOADED BY',
        size: 170,
        meta: {
          minSize: 120,
        },
      },
      columnHelper.display({
        id: 'menu',
        cell: ({ row }) => <FirmwareFileMenu file={row.original} />,
        header: () => null,
        size: 0,
        meta: {
          bodyCellProps: {
            className: styles.menu,
          },
          minSize: 40,
        },
      }),
    ],
    [uploadedAtSortingControl, versionSortingControl],
  );
